import { Fragment, useEffect, useState } from "react";
import { cabin_luggage as CabinLuggageIconKLM } from "../../../assets/icons/klm/index";
import { cabin_luggage as CabinLuggageIconTransavia } from "../../../assets/icons/transavia/index";
import { affiliates } from "../../../common/enums/affiliates";
import { GetAffiliate } from "../../../services/general.service";
import NoCabinBaggage from "./no-cabin-baggage.component";
import "./cabin-luggage.component.scss";
import { Alert } from "@mui/material";

export const CabinBaggageCard = ({
  luggageData,
  addBaggageHeader,
  baggageDetails,
  allAncillaryOptionsEmpty,
  onCabinBaggageSelectionChanged,
  AddBaggageDetailsRenderer,
  setCabinSelectionState,
  cabinSelectionState,
  errorState,
  setErrorState,
  isBaggageValid,
  onValidate,
  isCabinBaggageSoldOut,
}) => {
  const affiliate = GetAffiliate();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (
      errorState?.nothingSelected &&
      !isBaggageValid &&
      !isCabinBaggageSoldOut
    ) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [errorState, isBaggageValid]);

  if (!luggageData || luggageData.length === 0) return null;

  const cabinBaggageData = luggageData[0]?.fields;

  const handleChange = () => {
    setErrorState({
      nothingSelected: false,
      noCabinPerPassengerSelected: true,
    });
    setCabinSelectionState({ cabinSelected: true, noCabinSelected: false });
    if (!isBaggageValid) {
      onValidate(true);
    }
  };
  const cabinBaggage = baggageDetails?.cabinBaggageProducts?.[0] ?? null;
  const freeCabinBaggagePrice = 0;
  const transaviaFreeCabinBaggage =
    cabinBaggage?.isSelected &&
    parseFloat(cabinBaggage?.price) === freeCabinBaggagePrice;

  const displayCabinBaggageConditionDetails = (data) => {
    if (data) {
      return data.split("\n").map((info, index) => (
        <Fragment key={index}>
          {info}
          <br />
        </Fragment>
      ));
    }
  };

  const displayCabinLuggageIcon = () => {
    return affiliate === affiliates.klm ? (
      <img alt="KLMIcon" src={CabinLuggageIconKLM} />
    ) : (
      <img alt="TransaviaIcon" src={CabinLuggageIconTransavia} />
    );
  };

  const cabinBaggagePrice = () => {
    if (baggageDetails) {
      return baggageDetails.price;
    }
  };

  const cabinBaggagePriceNumber = parseFloat(cabinBaggagePrice);

  return (
    <>
      {cabinBaggageData?.cabinBaggageConditions && (
        <div className="cabin-baggage-card-component">
          <div className="cabin-luggage-section-header">
            {luggageData && cabinBaggageData?.cabinBaggageHeader}
          </div>
          {isError && (
            <Alert className="cabin-luggage-error" severity="error">
              <div>
                {cabinBaggageData && cabinBaggageData?.noBaggageWarning}{" "}
              </div>
            </Alert>
          )}
          {!allAncillaryOptionsEmpty &&
            !isCabinBaggageSoldOut &&
            !transaviaFreeCabinBaggage && (
              <NoCabinBaggage
                luggageData={luggageData}
                onCabinBaggageSelectionChanged={onCabinBaggageSelectionChanged}
                cabinBaggageOptions={baggageDetails}
                setCabinSelectionState={setCabinSelectionState}
                cabinSelectionState={cabinSelectionState}
                errorState={errorState}
                setErrorState={setErrorState}
                isError={isError}
              />
            )}
          <div
            className={`cabin-baggage-card-info ${
              cabinSelectionState.cabinSelected
                ? "checked"
                : isError
                ? "error"
                : isCabinBaggageSoldOut
                ? "not-available"
                : ""
            } ${allAncillaryOptionsEmpty ? "no-cabin" : ""}`}
            onClick={
              !allAncillaryOptionsEmpty && !isCabinBaggageSoldOut
                ? handleChange
                : undefined
            }
          >
            <div className="cabin-baggage-card-details">
              <div className="radio-button-cabin-luggage">
                <input
                  type="radio"
                  checked={cabinSelectionState.cabinSelected}
                  onChange={handleChange}
                  value="cabin-baggage"
                  name="cabin-baggage-radio"
                  className={`radio-button-cabin 
                    ${isError ? "error" : ""} 
                    ${isCabinBaggageSoldOut ? "not-available-input" : ""}`}
                  disabled={allAncillaryOptionsEmpty || isCabinBaggageSoldOut}
                />
              </div>
              <div className="cabin-baggage-card-description">
                <div className="cabin-baggage-card-title">
                  {addBaggageHeader || cabinBaggageData?.cabinBaggageLabel}
                </div>
                <div className="cabin-baggage-card-condition-details">
                  {AddBaggageDetailsRenderer
                    ? AddBaggageDetailsRenderer(
                        cabinBaggageData?.cabinBaggageConditions
                      )
                    : displayCabinBaggageConditionDetails(
                        cabinBaggageData?.cabinBaggageConditions
                      )}
                  {cabinBaggagePriceNumber > 0 && (
                    <div className="cabin-baggage-price">
                      {luggageData[0]?.fields?.fromTraveller}
                      <span className="price">€{cabinBaggagePrice}</span>
                      {luggageData[0]?.fields?.pricePerTraveller}
                    </div>
                  )}
                </div>
              </div>
              <div className="cabin-baggage-card-img">
                {displayCabinLuggageIcon()}
              </div>
            </div>
            {isCabinBaggageSoldOut && (
              <div className="sold-out-btn">
                {luggageData && cabinBaggageData?.soldOutButton}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
