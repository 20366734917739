
import { ReactComponent as RoomSizeKLM } from "../../assets/icons/klm/room-size-icon-klm.svg";
import { ReactComponent as IconMetersKLM } from  "../../assets/icons/klm/meters-icon-klm.svg";
import { ReactComponent as IconCotBedKLM } from  "../../assets/icons/klm/cot-bed-icon.svg";
import { ReactComponent as IconAirConditionKLM } from  "../../assets/icons/klm/aircondition-icon-klm.svg";
import { ReactComponent as IconBabyKLM } from  "../../assets/icons/klm/baby-icon-klm.svg";
import { ReactComponent as IconMealsKLM} from  "../../assets/icons/klm/meals-icon-klm.svg";
import { ReactComponent as IconBathroomKLM } from  "../../assets/icons/klm/bathroom-icon-klm.svg";
import { ReactComponent as IconPraticalKLM } from  "../../assets/icons/klm/pratical-icon-klm.svg";
import { ReactComponent as IconNoSmokingKLM } from  "../../assets/icons/klm/no-smoking-icon-klm.svg";
import { ReactComponent as IconQueenBedKLM } from  "../../assets/icons/klm/queen-bed-icon-klm.svg";
import { ReactComponent as IconKingBedKLM } from  "../../assets/icons/klm/king-bed-icon-klm.svg";
import { ReactComponent as IconDoubleBedKLM } from  "../../assets/icons/klm/double-bed-icon-klm.svg";
import { ReactComponent as IconSingleBedKLM } from  "../../assets/icons/klm/single-bed-icon-klm.svg";
import { ReactComponent as IconTvKLM } from  "../../assets/icons/klm/tv-icon-klm.svg";
import { ReactComponent as IconRoomSleepKLM } from "../../assets/icons/klm/room-type-sleep-icon-klm.svg";
import { ReactComponent as WifiKLM } from "../../assets/icons/klm/internet-icon-klm.svg";
import { ReactComponent as IconSmokingRoomsKLM } from "../../assets/icons/klm/smoking-icon-klm.svg";
import { ReactComponent as IconEcoFriendlyKLM } from "../../assets/icons/klm/eco-friendly-icon-klm.svg";
import { ReactComponent as IconWheelchairAccessibleKLM } from "../../assets/icons/klm/wheelchair-icon-klm.svg";
import { ReactComponent as IconCoffeeFacilitiesKLM } from "../../assets/icons/klm/coffee-icon-klm.svg";
import { ReactComponent as IconHousekeepingKLM } from "../../assets/icons/klm/house-keeping-icon-klm.svg";
import { ReactComponent as IconLivingRoomKLM } from "../../assets/icons/klm/living-room-icon-klm.svg";
import { ReactComponent as IconBedroomsKLM } from "../../assets/icons/klm/bedroom-icon-klm.svg";
import { ReactComponent as IconAccessibilityKLM } from "../../assets/icons/klm/accessibility-icon-klm.svg";
import { ReactComponent as IconSoundProofKLM } from "../../assets/icons/klm/sound-proof-icon-klm.svg";
import { ReactComponent as IconNeedToKnowKLM } from "../../assets/icons/klm/need-to-know-icon-klm.svg";


import { ReactComponent as RoomSizeTransavia } from "../../assets/icons/transavia/room-size-icon-transavia.svg";
import { ReactComponent as IconMetersTransavia } from  "../../assets/icons/transavia/room-size-icon-transavia.svg";
import { ReactComponent as IconAirConditionTransavia } from  "../../assets/icons/transavia/aircondition-icon-transavia.svg";
import { ReactComponent as IconBabyTransavia } from  "../../assets/icons/transavia/baby-cot-icon-transavia.svg";
import { ReactComponent as IconMealsTransavia } from  "../../assets/icons/transavia/food-icon-transavia.svg";
import { ReactComponent as IconBathroomTransavia } from  "../../assets/icons/transavia/shower-icon-transavia.svg";
import { ReactComponent as IconPraticalTransavia } from  "../../assets/icons/transavia/practical-icon-transavia.svg";
import { ReactComponent as IconNoSmokingTransavia } from  "../../assets/icons/transavia/no-smoking-icon-transavia.svg";
import { ReactComponent as IconQueenBedTransavia } from  "../../assets/icons/transavia/queen-bed-icon-transavia.svg";
import { ReactComponent as IconKingBedTransavia } from  "../../assets/icons/transavia/king-bed-icon-transavia.svg";
import { ReactComponent as IconDoubleBedTransavia } from  "../../assets/icons/transavia/double-bed-icon-transavia.svg";
import { ReactComponent as IconSingleBedTransavia } from  "../../assets/icons/transavia/single-bed-icon-transavia.svg";
import { ReactComponent as IconTvTransavia } from  "../../assets/icons/transavia/tv-icon-transavia.svg";
import { ReactComponent as IconRoomSleepTransavia } from "../../assets/icons/transavia/room-sleep-icon-transavia.svg";
import { ReactComponent as WifiTransavia } from "../../assets/icons/transavia/internet-icon-transavia.svg";
import { ReactComponent as IconSmokingRoomsTransavia } from "../../assets/icons/transavia/smoking-icon-transavia.svg";
import { ReactComponent as IconEcoFriendlyTransavia } from "../../assets/icons/transavia/eco-friendly-icon-transavia.svg";
import { ReactComponent as IconWheelchairAccessibleTransavia } from "../../assets/icons/transavia/wheelchair-icon-transavia.svg";
import { ReactComponent as IconCoffeeFacilitiesTransavia } from "../../assets/icons/transavia/coffee-icon-transavia.svg";
import { ReactComponent as IconHousekeepingTransavia } from "../../assets/icons/transavia/house-keeping-icon-transavia.svg";
import { ReactComponent as IconLivingRoomTransavia } from "../../assets/icons/transavia/living-room-icon-transavia.svg";
import { ReactComponent as IconBedroomsTransavia } from "../../assets/icons/transavia/bedroom-icon-transavia.svg";
import { ReactComponent as IconAccessibilityTransavia } from "../../assets/icons/transavia/accessibility-icon-transavia.svg";
import { ReactComponent as IconSoundProofTransavia } from "../../assets/icons/transavia/sound-proof-icon-transavia.svg";
import { ReactComponent as IconNeedToKnowTransavia } from "../../assets/icons/transavia/need-to-know-icon-transavia.svg";

export const primaryCategories = [
  "CotBed",
  "RoomSleep",
  "Internet",
  "WiFiPaid",
  "Feet",
  "RoomSizeSqm",
  "Bathroom"
];

export const accomodationCategories = {
    RoomSize: "sq-foot",
    Feet: "feet",
    RoomSizeSqm: "size",
    Layout: "Layout",
    Internet: "Internet",
    Entertainment: "Entertainment",
    FoodAndDrink: "Food & Drink",
    Sleep: "Sleep",
    QueenBed: "Queen",
    Bathroom: "Bathroom",
    Practical: "Practical",
    Comfort: "Comfort",
    NonSmoking: "Non-Smoking",
    DoubleBed: "Double Bed",
    TwinBed: "Twin Beds",
    SingleBed: "Single Bed",
    KingBed: "King Bed",
    CotBed: "Cot Bed",
    Baby: "Baby",
    RoomSleep: "Room/bed",
    RoomSqm: "size",
    SmokingRooms: "Smoking rooms",
    EcoFriendly: "Eco-friendly",
    WheelchairAccessible: "Wheelchair-accessible",
    CoffeeFacilities: "Tea and coffee",
    Housekeeping: "Housekeeping",
    LivingRoom: "Living room",
    NumberOfBedrooms: "Number of bedrooms",
    Accessibility: "Accessibility: Low – height counters",
    WiFiPaid: "Wi-fi",
    SoundProof: "Soundproof",
    NeedToKnow: "Need to Know",
  };

  export const accomodationCategoriesNL = {
    RoomSize: "meter",
    RoomSizeSqm: "maat",
    Feet: "feet",
    Layout: "Indeling",
    Internet: "Internet",
    Entertainment: "Entertainment",
    FoodAndDrink: "Eten & Drinken",
    Sleep: "Slapen",
    QueenBed: "Queensize ",
    Bathroom: "Badkamer",
    Practical: "Praktisch",
    Comfort: "Comfort",
    NonSmoking: "Niet-roken",
    DoubleBed: "Tweepersoonsbed",
    TwinBed: "eenpersoonsbedden",
    SingleBed: "Eenpersoonsbed",
    KingBed: "Kingsize bed",
    CotBed: "Kinderbed",
    Baby: "Baby",
    RoomSleep: "Kamer/bedtype",
    RoomSqm: "Grootte",
    SmokingRooms: "Rookkamers",
    EcoFriendly: "Milieuvriendelijk",
    WheelchairAccessible: "Rolstoeltoegankelijk",
    CoffeeFacilities: "Koffie- en theefaciliteiten",
    Housekeeping: "Schoonmaakservice",
    LivingRoom: "Woonkamer",
    NumberOfBedrooms: "Aantal slaapkamers",
    Accessibility: "Toegankelijkheid: Lage balies",
    WiFiPaid: "Wi-fi",
    SoundProof: "Geluiddichte",
    NeedToKnow: "Belangrijke informatie",
  };
  
  export const klmAccomodationIcons = {
    [accomodationCategories.RoomSize]: <RoomSizeKLM />,
    [accomodationCategories.RoomSizeSqm]: <RoomSizeKLM />,
    [accomodationCategories.Feet]: <RoomSizeKLM />,
    [accomodationCategories.Layout]: <IconMetersKLM />,
    [accomodationCategories.Internet]: <WifiKLM />,
    [accomodationCategories.Entertainment]: <IconTvKLM />,
    [accomodationCategories.FoodAndDrink]: <IconMealsKLM />,
    [accomodationCategories.Sleep]: <IconQueenBedKLM />,
    [accomodationCategories.QueenBed]: <IconQueenBedKLM />,
    [accomodationCategories.Bathroom]: <IconBathroomKLM />,
    [accomodationCategories.Practical]: <IconPraticalKLM />,
    [accomodationCategories.Comfort]: <IconAirConditionKLM />,
    [accomodationCategories.NonSmoking]: <IconNoSmokingKLM />,
    [accomodationCategories.DoubleBed]: <IconDoubleBedKLM />,
    [accomodationCategories.TwinBed]: <IconSingleBedKLM />,
    [accomodationCategories.SingleBed]: <IconSingleBedKLM />,
    [accomodationCategories.KingBed]: <IconKingBedKLM />,
    [accomodationCategories.CotBed]: <IconCotBedKLM />,
    [accomodationCategories.Baby]: <IconBabyKLM />,
    [accomodationCategories.RoomSleep]: <IconRoomSleepKLM />,
    [accomodationCategories.WiFiPaid]: <WifiKLM />,
    [accomodationCategories.SmokingRooms]: <IconSmokingRoomsKLM />,
    [accomodationCategories.EcoFriendly]: <IconEcoFriendlyKLM />,
    [accomodationCategories.WheelchairAccessible]: <IconWheelchairAccessibleKLM />,
    [accomodationCategories.CoffeeFacilities]: <IconCoffeeFacilitiesKLM />,
    [accomodationCategories.Housekeeping]: <IconHousekeepingKLM />,
    [accomodationCategories.LivingRoom]: <IconLivingRoomKLM />,
    [accomodationCategories.NumberOfBedrooms]: <IconBedroomsKLM />,
    [accomodationCategories.Accessibility]: <IconAccessibilityKLM />,
    [accomodationCategories.SoundProof]: <IconSoundProofKLM />,
    [accomodationCategories.NeedToKnow]: <IconNeedToKnowKLM />,
  };
  
  export const transaviaAccomodationIcons = {
    [accomodationCategories.RoomSize]: <RoomSizeTransavia />,
    [accomodationCategories.RoomSizeSqm]: <RoomSizeTransavia />,
    [accomodationCategories.Feet]: <RoomSizeTransavia />,
    [accomodationCategories.Layout]: <IconMetersTransavia />,
    [accomodationCategories.Internet]: <WifiTransavia />,
    [accomodationCategories.Entertainment]: <IconTvTransavia />,
    [accomodationCategories.FoodAndDrink]: <IconMealsTransavia />,
    [accomodationCategories.Sleep]: <IconQueenBedTransavia />,
    [accomodationCategories.QueenBed]: <IconQueenBedTransavia />,
    [accomodationCategories.Bathroom]: <IconBathroomTransavia />,
    [accomodationCategories.Practical]: <IconPraticalTransavia />,
    [accomodationCategories.Comfort]: <IconAirConditionTransavia />,
    [accomodationCategories.NonSmoking]: <IconNoSmokingTransavia />,
    [accomodationCategories.DoubleBed]: <IconDoubleBedTransavia />,
    [accomodationCategories.TwinBed]: <IconSingleBedTransavia />,
    [accomodationCategories.SingleBed]: <IconSingleBedTransavia />,
    [accomodationCategories.KingBed]: <IconKingBedTransavia />,
    [accomodationCategories.Baby]: <IconBabyTransavia />,
    [accomodationCategories.RoomSleep]: <IconRoomSleepTransavia />,
    [accomodationCategories.WiFiPaid]: <WifiTransavia />,
    [accomodationCategories.SmokingRooms]: <IconSmokingRoomsTransavia />,
    [accomodationCategories.EcoFriendly]: <IconEcoFriendlyTransavia />,
    [accomodationCategories.WheelchairAccessible]: <IconWheelchairAccessibleTransavia />,
    [accomodationCategories.CoffeeFacilities]: <IconCoffeeFacilitiesTransavia />,
    [accomodationCategories.Housekeeping]: <IconHousekeepingTransavia />,
    [accomodationCategories.LivingRoom]: <IconLivingRoomTransavia />,
    [accomodationCategories.NumberOfBedrooms]: <IconBedroomsTransavia />,
    [accomodationCategories.Accessibility]: <IconAccessibilityTransavia />,
    [accomodationCategories.SoundProof]: <IconSoundProofTransavia />,
    [accomodationCategories.NeedToKnow]: <IconNeedToKnowTransavia />,
  }

  export const klmAccomodationIconsNL = {
    [accomodationCategoriesNL.RoomSize]: <RoomSizeKLM />,
    [accomodationCategories.RoomSizeSqm]: <RoomSizeKLM />,
    [accomodationCategories.Feet]: <RoomSizeKLM />,
    [accomodationCategoriesNL.Layout]: <IconMetersKLM />,
    [accomodationCategoriesNL.Internet]: <WifiKLM />,
    [accomodationCategoriesNL.Entertainment]: <IconTvKLM />,
    [accomodationCategoriesNL.FoodAndDrink]: <IconMealsKLM />,
    [accomodationCategoriesNL.Sleep]: <IconQueenBedKLM />,
    [accomodationCategories.QueenBed]: <IconQueenBedKLM />,
    [accomodationCategoriesNL.Bathroom]: <IconBathroomKLM />,
    [accomodationCategoriesNL.Practical]: <IconPraticalKLM />,
    [accomodationCategoriesNL.Comfort]: <IconAirConditionKLM />,
    [accomodationCategoriesNL.NonSmoking]: <IconNoSmokingKLM />,
    [accomodationCategoriesNL.DoubleBed]: <IconDoubleBedKLM />,
    [accomodationCategoriesNL.TwinBed]: <IconSingleBedKLM />,
    [accomodationCategoriesNL.SingleBed]: <IconSingleBedKLM />,
    [accomodationCategoriesNL.KingBed]: <IconKingBedKLM />,
    [accomodationCategoriesNL.CotBed]: <IconCotBedKLM />,
    [accomodationCategoriesNL.Baby]: <IconBabyKLM />,
    [accomodationCategoriesNL.RoomSleep]: <IconRoomSleepKLM />,
    [accomodationCategoriesNL.WiFiPaid]: <WifiKLM />,
    [accomodationCategoriesNL.SmokingRooms]: <IconSmokingRoomsKLM />,
    [accomodationCategoriesNL.EcoFriendly]: <IconEcoFriendlyKLM />,
    [accomodationCategoriesNL.WheelchairAccessible]: <IconWheelchairAccessibleKLM />,
    [accomodationCategoriesNL.CoffeeFacilities]: <IconCoffeeFacilitiesKLM />,
    [accomodationCategoriesNL.Housekeeping]: <IconHousekeepingKLM />,
    [accomodationCategoriesNL.LivingRoom]: <IconLivingRoomKLM />,
    [accomodationCategoriesNL.NumberOfBedrooms]: <IconBedroomsKLM />,
    [accomodationCategoriesNL.Accessibility]: <IconAccessibilityKLM />,
    [accomodationCategoriesNL.SoundProof]: <IconSoundProofKLM />,
    [accomodationCategoriesNL.NeedToKnow]: <IconNeedToKnowKLM />,
  };
  
  export const transaviaAccomodationIconsNL = {
    [accomodationCategoriesNL.RoomSize]: <RoomSizeTransavia />,
    [accomodationCategories.RoomSizeSqm]: <RoomSizeTransavia />,
    [accomodationCategories.Feet]: <RoomSizeTransavia />,
    [accomodationCategoriesNL.Layout]: <IconMetersTransavia />,
    [accomodationCategoriesNL.Internet]: <WifiTransavia />,
    [accomodationCategoriesNL.Entertainment]: <IconTvTransavia />,
    [accomodationCategoriesNL.FoodAndDrink]: <IconMealsTransavia />,
    [accomodationCategoriesNL.Sleep]: <IconQueenBedTransavia />,
    [accomodationCategories.QueenBed]: <IconQueenBedTransavia />,
    [accomodationCategoriesNL.Bathroom]: <IconBathroomTransavia />,
    [accomodationCategoriesNL.Practical]: <IconPraticalTransavia />,
    [accomodationCategoriesNL.Comfort]: <IconAirConditionTransavia />,
    [accomodationCategoriesNL.NonSmoking]: <IconNoSmokingTransavia />,
    [accomodationCategoriesNL.DoubleBed]: <IconDoubleBedTransavia />,
    [accomodationCategoriesNL.TwinBed]: <IconSingleBedTransavia />,
    [accomodationCategoriesNL.SingleBed]: <IconSingleBedTransavia />,
    [accomodationCategoriesNL.KingBed]: <IconKingBedTransavia />,
    [accomodationCategoriesNL.Baby]: <IconBabyTransavia />,
    [accomodationCategoriesNL.RoomSleep]: <IconRoomSleepTransavia />,
    [accomodationCategoriesNL.WiFiPaid]: <WifiTransavia />,
    [accomodationCategoriesNL.SmokingRooms]: <IconSmokingRoomsTransavia />,
    [accomodationCategoriesNL.EcoFriendly]: <IconEcoFriendlyTransavia />,
    [accomodationCategoriesNL.WheelchairAccessible]: <IconWheelchairAccessibleTransavia />,
    [accomodationCategoriesNL.CoffeeFacilities]: <IconCoffeeFacilitiesTransavia />,
    [accomodationCategoriesNL.Housekeeping]: <IconHousekeepingTransavia />,
    [accomodationCategoriesNL.LivingRoom]: <IconLivingRoomTransavia />,
    [accomodationCategoriesNL.NumberOfBedrooms]: <IconBedroomsTransavia />,
    [accomodationCategoriesNL.Accessibility]: <IconAccessibilityTransavia />,
    [accomodationCategoriesNL.SoundProof]: <IconSoundProofTransavia />,
    [accomodationCategoriesNL.NeedToKnow]: <IconNeedToKnowTransavia />,
  }