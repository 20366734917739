import "./traveller-rooms.component.scss";
import { useEffect, useRef, useState } from "react";
import { TravellerSelectorComponent } from "./traveller-selector/traveller-selector.component";
import { useSearchParams } from "react-router-dom";
import { searchParamsEnum } from "../../../common/enums/searchParamsEnum";
import { LocalStorageService } from "../../../services/local-storage.service";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";

export const TravellerRoomsComponent = ({
  contentfulButtons,
  contentfulRoomAllocation,
  roomAllocation,
  updateRoomAllocation,
  parseRoomAllocationToCode,
  closeTravellerRoomsComponent,
  closeTravellerRoomsComponentWithoutChange,
  setShowTravellerRoomsComponent,
  setIsSearchPending,
}) => {
  const containerRef = useRef(null);
  const clickEventListenerTrigger = useRef(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const defaultButtons = {
    adultsMinusButtonDisabled: true,
    adultsPlusButtonDisabled: false,
    childrenMinusButtonDisabled: true,
    childrenPlusButtonDisabled: false,
    babiesMinusButtonDisabled: true,
    babiesPlusButtonDisabled: false,
  };

  const defaultValues = {
    rooms: {
      min: 1,
      max: 4,
    },
    travellers: {
      min: 1,
      max: 9,
    },
    travellersPerRoom: {
      min: 1,
      max: 4,
    },
  };

  const defaultRoom = {
    adults: 1,
    children: 0,
    babies: 0,
    buttons: JSON.parse(JSON.stringify(defaultButtons)),
  };

  const [rooms, setStateRooms] = useState([defaultRoom]);
  const [numberOfAdults, setStateNumberOfAdults] = useState(0);
  const [numberOfTravellers, setStateNumberOfTravellers] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    presetRoomAllocation();
  }, []);

  useEffect(() => {
    setNumberOfTravellers();
  }, [rooms]);

  const hasChanges = () => {
    const storedRooms = JSON.parse(
      LocalStorageService.getLocalStorageItem(LocalStorageEnum.ROOM_ALLOCATION)
    );

    if (!storedRooms) {
      return true;
    }

    const filteredCurrentRooms = rooms.map(({ adults, children, babies }) => ({
      adults,
      children,
      babies,
    }));

    const filteredStoredRooms = storedRooms.map(
      ({ adults, children, babies }) => ({
        adults,
        children,
        babies,
      })
    );

    if (
      JSON.stringify(filteredCurrentRooms) !==
      JSON.stringify(filteredStoredRooms)
    ) {
      return true;
    }

    const currentNumberOfAdults = rooms.reduce(
      (total, room) => total + room.adults,
      0
    );
    const currentNumberOfTravellers = rooms.reduce(
      (total, room) => total + room.adults + room.children,
      0
    );
    const storedNumberOfAdults = storedRooms.reduce(
      (total, room) => total + room.adults,
      0
    );
    const storedNumberOfTravellers = storedRooms.reduce(
      (total, room) => total + room.adults + room.children,
      0
    );
    const hasAdultsChanged = currentNumberOfAdults !== storedNumberOfAdults;
    const hasTravellersChanged =
      currentNumberOfTravellers !== storedNumberOfTravellers;

    return (
      hasAdultsChanged ||
      hasTravellersChanged ||
      rooms.length !== storedRooms.length
    );
  };

  const saveChanges = () => {
    const updatedRooms = JSON.parse(JSON.stringify(rooms));
    LocalStorageService.setLocalStorageItem(
      LocalStorageEnum.ROOM_ALLOCATION,
      updatedRooms
    );
    updateRoomAllocation(updatedRooms);
    setSearchParams((params) => {
      params.set(
        searchParamsEnum.roomAllocation,
        parseRoomAllocationToCode(updatedRooms)
      );
      return params;
    });
    setIsSearchPending(true);
  };

  useEffect(() => {
    presetRoomAllocation();
  }, []);

  useEffect(() => {
    const clickEventHandler = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        !event.target.getAttribute("data-trigger") &&
        clickEventListenerTrigger.current
      ) {
        if (hasChanges()) {
          saveChanges();
          closeTravellerRoomsComponent();
        } else {
          setIsModalOpen(false);
          setShowTravellerRoomsComponent(false);
          closeTravellerRoomsComponentWithoutChange();
        }
      } else {
        clickEventListenerTrigger.current = true;
      }
    };

    document.addEventListener("click", clickEventHandler);

    return () => {
      document.removeEventListener("click", clickEventHandler);
    };
  }, [containerRef, rooms]);

  const handleSaveClick = (event) => {
    event.preventDefault();

    if (hasChanges()) {
      saveChanges();
      closeTravellerRoomsComponent();
    }

    setIsModalOpen(false);
    setShowTravellerRoomsComponent(false);
    closeTravellerRoomsComponentWithoutChange();
  };

  const handleCloseClick = () => {
    setIsModalOpen(false);
    setShowTravellerRoomsComponent(false);
    closeTravellerRoomsComponentWithoutChange();
  };

  const presetRoomAllocation = () => {
    const savedRoomAllocation = LocalStorageService.getLocalStorageItem(
      LocalStorageEnum.ROOM_ALLOCATION
    );
    if (savedRoomAllocation) {
      const parsedRoomAllocation = JSON.parse(savedRoomAllocation);
      parsedRoomAllocation.forEach((e) => {
        e["buttons"] = JSON.parse(JSON.stringify(defaultButtons));
      });
      setStateRooms(setButtonsEnabledOrDisabled(parsedRoomAllocation));
    } else {
      const defaultRoomAllocation = [
        {
          adults: 2,
          children: 0,
          babies: 0,
          buttons: JSON.parse(JSON.stringify(defaultButtons)),
        },
      ];

      setStateRooms(setButtonsEnabledOrDisabled(defaultRoomAllocation));
      LocalStorageService.setLocalStorageItem(
        LocalStorageEnum.ROOM_ALLOCATION,
        defaultRoomAllocation
      );
    }
  };

  const setNumberOfRooms = (operator) => {
    const updatedRooms = [...rooms];
    if (
      operator === "+" &&
      rooms.length < defaultValues.rooms.max &&
      numberOfAdults >= rooms.length &&
      numberOfTravellers < defaultValues.travellers.max
    ) {
      updatedRooms.push(defaultRoom);
      setStateRooms(setButtonsEnabledOrDisabled(updatedRooms));
    } else if (operator === "-" && rooms.length > defaultValues.rooms.min) {
      const roomsToProcess = [...updatedRooms.slice(0, -1)];
      autoAdjustNumberOfBabiesToNumberOfAdults(roomsToProcess);
      setStateRooms(setButtonsEnabledOrDisabled(roomsToProcess));
    }
  };

  const deleteRoom = (index) => {
    const updatedRooms = [...rooms].filter((e, i) => i !== index);
    autoAdjustNumberOfBabiesToNumberOfAdults(updatedRooms);
    setStateRooms(setButtonsEnabledOrDisabled(updatedRooms));
  };

  const getNumberOfAdults = (rooms) => {
    return rooms.map((e) => e.adults.valueOf()).reduce((a, b) => a + b);
  };

  const getNumberOfChildren = (rooms) => {
    return rooms.map((e) => e.children.valueOf()).reduce((a, b) => a + b);
  };

  const getNumberOfBabies = (rooms) => {
    return rooms.map((e) => e.babies.valueOf()).reduce((a, b) => a + b);
  };

  const setNumberOfTravellers = () => {
    const roomsToProcess = [...rooms];
    const numberOfAdults = getNumberOfAdults(roomsToProcess);
    const numberOfChildren = getNumberOfChildren(roomsToProcess);
    setStateNumberOfAdults(numberOfAdults);
    setStateNumberOfTravellers(numberOfAdults + numberOfChildren);
  };

  const setNumberOfTravellersPerRoom = (index, operator, travellers) => {
    const updatedRooms = [...rooms];
    switch (travellers) {
      case "adults":
        setNumberOfAdults(index, operator, updatedRooms);
        break;
      case "children":
        setNumberOfChildren(index, operator, updatedRooms);
        break;
      case "babies":
        setNumberOfBabies(index, operator, updatedRooms);
        break;
      default:
        console.error("unknown travellers type");
    }
    setStateRooms(setButtonsEnabledOrDisabled(updatedRooms));
  };

  const setNumberOfAdults = (index, operator, roomsToProcess) => {
    if (operator === "-" && roomsToProcess[index].adults > defaultRoom.adults) {
      roomsToProcess[index].adults -= 1;
      autoAdjustNumberOfBabiesToNumberOfAdults(roomsToProcess);
    } else if (
      operator === "+" &&
      !maxNumberOfTravellersPerRoomReached(
        roomsToProcess[index].adults,
        roomsToProcess[index].children
      )
    ) {
      if (numberOfTravellers < defaultValues.travellers.max) {
        roomsToProcess[index].adults += 1;
      }
    }
  };

  const setNumberOfChildren = (index, operator, roomsToProcess) => {
    if (
      operator === "-" &&
      roomsToProcess[index].children > defaultRoom.children
    ) {
      roomsToProcess[index].children -= 1;
    } else if (
      operator === "+" &&
      !maxNumberOfTravellersPerRoomReached(
        roomsToProcess[index].adults,
        roomsToProcess[index].children
      )
    ) {
      if (numberOfTravellers < defaultValues.travellers.max) {
        roomsToProcess[index].children += 1;
      }
    }
  };

  const setNumberOfBabies = (index, operator, roomsToProcess) => {
    const numberOfAdults = getNumberOfAdults(roomsToProcess);
    const numberOfBabies = getNumberOfBabies(roomsToProcess);

    if (operator === "-" && roomsToProcess[index].babies > defaultRoom.babies) {
      roomsToProcess[index].babies -= 1;
    } else if (operator === "+") {
      if (
        roomsToProcess[index].babies < defaultValues.travellersPerRoom.max &&
        numberOfBabies < numberOfAdults
      ) {
        roomsToProcess[index].babies += 1;
      }
    }
  };

  const maxNumberOfTravellersReached = () => {
    return numberOfTravellers >= defaultValues.travellers.max;
  };

  const maxNumberOfTravellersPerRoomReached = (adults, children) => {
    return adults + children >= defaultValues.travellersPerRoom.max;
  };

  const autoAdjustNumberOfBabiesToNumberOfAdults = (rooms) => {
    rooms.map((room) => {
      if (room.babies > room.adults) {
        room.babies = room.adults;
      }
    });
  };

  const setButtonsEnabledOrDisabled = (rooms) => {
    const numberOfAdults = getNumberOfAdults(rooms);
    const numberOfBabies = getNumberOfBabies(rooms);
    const limitOfBabiesReached = numberOfBabies === numberOfAdults;

    rooms.forEach((room) => {
      const limitOfTravellersReached =
        maxNumberOfTravellersPerRoomReached(room.adults, room.children) ||
        maxNumberOfTravellersReached();

      if (
        room.adults > defaultRoom.adults &&
        room.adults < defaultValues.travellersPerRoom.max
      ) {
        room.buttons.adultsMinusButtonDisabled = false;
      } else {
        room.buttons.adultsMinusButtonDisabled =
          room.adults === defaultRoom.adults;
      }
      room.buttons.adultsPlusButtonDisabled = limitOfTravellersReached;

      if (
        room.children > defaultRoom.children &&
        room.adults < defaultValues.travellersPerRoom.max
      ) {
        room.buttons.childrenMinusButtonDisabled = false;
      } else {
        room.buttons.childrenMinusButtonDisabled =
          room.children === defaultRoom.children;
      }
      room.buttons.childrenPlusButtonDisabled = limitOfTravellersReached;

      if (room.babies > defaultRoom.babies && room.babies < room.adults) {
        room.buttons.babiesMinusButtonDisabled = false;
        room.buttons.babiesPlusButtonDisabled = limitOfBabiesReached;
      } else {
        room.buttons.babiesMinusButtonDisabled =
          room.babies === defaultRoom.babies;
        room.buttons.babiesPlusButtonDisabled =
          room.babies === room.adults || limitOfBabiesReached;
      }
    });

    return rooms;
  };

  return (
    <div className="traveller-rooms-component-wrapper">
      {/* Buttons */}
      <div className="button-container">
        <div className="button" onClick={handleCloseClick}>
          {contentfulButtons?.cancel}
        </div>
        <div className="button" onClick={handleSaveClick}>
          {contentfulButtons?.save}
        </div>
      </div>
      <div
        id="traveller-rooms-component"
        className="traveller-rooms-component"
        ref={containerRef}
      >
        {/* Info */}
        <div className="room-info">
          <div>
            <div className="info-icon"></div>
          </div>
          <div>{contentfulRoomAllocation?.roomInfoText}</div>
        </div>

        {/* Room selector */}
        <div className="room-selector">
          <div className="selector-text-container">
            <div className="room-selector-header">
              {contentfulRoomAllocation?.roomSelectorHeader}
            </div>
            <div className="room-selector-text">
              {contentfulRoomAllocation?.roomSelectorText}
            </div>
          </div>
          <div className="selector-numeric-stepper">
            <div
              className={
                rooms.length > defaultValues.rooms.min
                  ? "minus-button enabled"
                  : "minus-button disabled"
              }
              onClick={() => {
                setNumberOfRooms("-");
              }}
            >
              <div className="icon"></div>
            </div>
            <div className="value-indicator"> {rooms.length} </div>
            <div
              className={
                rooms.length < defaultValues.rooms.max &&
                numberOfAdults >= rooms.length &&
                numberOfTravellers < defaultValues.travellers.max
                  ? "plus-button enabled"
                  : "plus-button disabled"
              }
              onClick={() => {
                setNumberOfRooms("+");
              }}
            >
              <div className="icon"></div>
            </div>
          </div>
        </div>

        {/* Traveller selector */}
        <>
          {rooms.map((room, index) => {
            return (
              <div className="traveller-selector-container" key={index}>
                <TravellerSelectorComponent
                  contentfulButtons={contentfulButtons}
                  contentfulRoomAllocation={contentfulRoomAllocation}
                  index={index}
                  room={room}
                  deleteRoom={
                    rooms.length > defaultValues.rooms.min ? deleteRoom : null
                  }
                  setNumberOfTravellersPerRoom={setNumberOfTravellersPerRoom}
                />
              </div>
            );
          })}
        </>

        {/* Buttons only desktop */}
        <div className="button-container-desktop">
          <div className="button" onClick={handleCloseClick}>
            {contentfulButtons?.cancel}
          </div>
          <div className="button" onClick={handleSaveClick}>
            {contentfulButtons?.save}
          </div>
        </div>
      </div>
    </div>
  );
};
