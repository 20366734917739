import { Box, Modal } from "@mui/material";
import "./accommodation-option-details-modal.scss";
import CloseIcon from "@mui/icons-material/Close";
import { HotelImageFlipper } from "../hotel/hotel-image-flipper/hotel-image-flipper.component";
import { ReactComponent as IconPraticalKLM } from "../../assets/icons/klm/pratical-icon-klm.svg";
import { ReactComponent as IconPraticalTransavia } from "../../assets/icons/transavia/practical-icon-transavia.svg";
import { GetAffiliate } from "../../services/general.service";
import { affiliates } from "../../common/enums/affiliates";

export const AccommodationOptionDetailsModal = ({
  isOpen,
  handleClose,
  roomDetails,
  uncategorizedData,
  categoriesData,
  contentfulData,
  getAffiliateIcons,
  renderKeyRoomDetails,
}) => {
  const affiliateIcons = getAffiliateIcons();

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className="room-details-modal-wrapper"
    >
      <Box className="room-details-modal">
        <div className="room-details-modal__header">
          <header>{contentfulData && contentfulData.detailsHeader}</header>
          <CloseIcon className="header__close-btn" onClick={handleClose} />
        </div>
        <div className="room-details-modal__body">
          <HotelImageFlipper hotelImages={roomDetails.images} />
          <div className="body__room-header">
            {roomDetails.roomDescriptions}
          </div>
          <div className="uncategorized-section">
            {renderKeyRoomDetails(uncategorizedData, null)}
          </div>

          <div className="categories-section">
            <div className="categories-columns">
              {categoriesData.map((category, index) => {
                if (
                  !category.categoryDescription ||
                  category.categoryDescription.length === 0
                ) {
                  return null;
                }

                const icon = affiliateIcons[category.categoryName] ? (
                  affiliateIcons[category.categoryName]
                ) : GetAffiliate() === affiliates.klm ? (
                  <IconPraticalKLM />
                ) : (
                  <IconPraticalTransavia />
                );
                return (
                  <div key={index} className="category-item">
                    <div className="category-title">
                      {icon && <div className="category-icon">{icon}</div>}
                      <div className="category-name">
                        {category.categoryName}
                      </div>
                    </div>
                    <ul className="category-description">
                      {category.categoryDescription.map((desc, descIndex) => (
                        <li key={descIndex}>
                          {desc.charAt(0).toUpperCase() + desc.slice(1)}
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="body__bottom-quote">
            <p>{contentfulData?.roomDescriptionModalQuote}</p>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
