import "./departure-dropdown.styles.scss";
import { useEffect, useRef, useState } from "react";
import { affiliates } from "../../../common/enums/affiliates";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";
import { LocalStorageService } from "../../../services/local-storage.service";
import { GetAffiliate } from "../../../services/general.service";
import { useSearchParams } from "react-router-dom";
import { searchParamsEnum } from "../../../common/enums/searchParamsEnum";
import { DepartureLocationEnum } from "../../../common/enums/departureLocationEnum";

export const DepartureDropdownComponent = ({
  optionList,
  contentfulButtons,
  isMobile,
  departureLocationsDeepLinkParam,
  isResultsPage,
  onClose,
  performSearch,
  setSelectedDepartureInParent,
  isSearchPending,
  setIsSearchPending,
}) => {
  const containerRef = useRef(null);
  const [selectedDeparture, setSelectedDeparture] = useState({
    selectedByUser: true,
    departureLocationCode: DepartureLocationEnum.Amsterdam.Code,
    departureLocationName: DepartureLocationEnum.Amsterdam.DisplayName,
  });
  const [tempSelectedDepartureForMobile, setTempSelectedDepartureForMobile] =
    useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortedOptionList, setSortedOptionList] = useState(optionList);
  const [isDepartureUpdating, setIsDepartureUpdating] = useState(false);

  const allDeparturesConst = {
    selectedByUser:
      selectedDeparture?.departureLocationCode === "" ||
      tempSelectedDepartureForMobile?.departureLocationCode === "" ||
      JSON.parse(
        LocalStorageService.getLocalStorageItem(
          LocalStorageEnum.IS_ALL_DEPARTURES
        )
      ),
    departureLocationCode: "",
    departureLocationName: contentfulButtons?.departureLocationSelectAll,
  };

  useEffect(() => {
    if (departureLocationsDeepLinkParam) {
      setSelectedDeparture(departureLocationsDeepLinkParam);
      setTempSelectedDepartureForMobile(departureLocationsDeepLinkParam);
    }
  }, [departureLocationsDeepLinkParam]);

  useEffect(() => {
    if (GetAffiliate() === affiliates.klm) {
      setSearchParams((params) => {
        params.set(
          searchParamsEnum.departureLocation,
          selectedDeparture.departureLocationCode
        );
        return params;
      });
    } else if (GetAffiliate() === affiliates.transavia) {
      if (isResultsPage) {
        if (selectedDeparture.departureLocationCode === "") {
          LocalStorageService.setLocalStorageItem(
            LocalStorageEnum.IS_ALL_DEPARTURES,
            true
          );
        } else {
          LocalStorageService.setLocalStorageItem(
            LocalStorageEnum.IS_ALL_DEPARTURES,
            false
          );
        }
      }
    }
  }, [selectedDeparture]);

  useEffect(() => {
    if (optionList) {
      if (!departureLocationsDeepLinkParam) {
        if (GetAffiliate() === affiliates.transavia) {
          const departureLocationFromDeeplink =
            searchParams.get(searchParamsEnum.departureLocation) ?? "";
          const departureLocationName =
            optionList.find(
              (option) =>
                option.departureLocationCode === departureLocationFromDeeplink
            )?.departureLocationName ??
            contentfulButtons?.departureLocationSelectAll;
          setSelectedDeparture({
            selectedByUser: true,
            departureLocationCode: departureLocationFromDeeplink,
            departureLocationName: departureLocationName,
          });
          setTempSelectedDepartureForMobile({
            selectedByUser: true,
            departureLocationCode: departureLocationFromDeeplink,
            departureLocationName: departureLocationName,
          });
          setDepartureOptions(optionList);
        }
      } else {
        setSearchParams((params) => {
          params.set(
            searchParamsEnum.departureLocation,
            departureLocationsDeepLinkParam.departureLocationCode
          );
          return params;
        });
        setSelectedDeparture(departureLocationsDeepLinkParam);
        setTempSelectedDepartureForMobile(departureLocationsDeepLinkParam);
        setSelectedDepartureInParent(departureLocationsDeepLinkParam);
        if (GetAffiliate() === affiliates.klm) {
          setSortedOptionList([departureLocationsDeepLinkParam]);
        }
      }
    }
  }, [optionList, contentfulButtons]);

  const selectDepartureLocation = (option) => {
    if (
      selectedDeparture.departureLocationCode !== option.departureLocationCode
    ) {
      setSelectedDeparture(option);
      setTempSelectedDepartureForMobile(option);
      setSelectedDepartureInParent(option);

      setSearchParams((params) => {
        params.set(
          searchParamsEnum.departureLocation,
          option.departureLocationCode
        );
        return params;
      });
      setIsSearchPending(true);
      setIsDepartureUpdating(true);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (isSearchPending && isDepartureUpdating) {
      setIsDepartureUpdating(false);
      if (onClose) {
        onClose();
      }
    }
  }, [searchParams, isSearchPending, isDepartureUpdating, onClose]);

  const handleOpenDropdown = () => {
    setIsOpen(!isOpen);
    if (isMobile) {
      setTempSelectedDepartureForMobile(selectedDeparture);
    }
  };

  const handleIsMobileSelection = (option) => {
    return isMobile
      ? option?.departureLocationName ===
          tempSelectedDepartureForMobile?.departureLocationName
      : option?.departureLocationName ===
          selectedDeparture?.departureLocationName;
  };

  useEffect(() => {
    const finalSelectedDeparture = isMobile
      ? tempSelectedDepartureForMobile
      : selectedDeparture;

    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    if (selectedDeparture && sortedOptionList) {
      const tempList = sortedOptionList;
      tempList.forEach((item) => {
        if (item) {
          if (
            item?.departureLocationCode ===
            finalSelectedDeparture?.departureLocationCode
          ) {
            item.selectedByUser = true;
          } else {
            item.selectedByUser = false;
          }
        }
      });
      setSortedOptionList(
        tempList.sort((a, b) => b.selectedByUser - a.selectedByUser)
      );
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen, selectedDeparture, tempSelectedDepartureForMobile]);

  const setDepartureOptions = (list) => {
    if (!list.find((option) => option.departureLocationCode === "")) {
      if (isResultsPage) {
        setSortedOptionList([...list, allDeparturesConst]);
      }
    }
  };

  useEffect(() => {
    if (
      GetAffiliate() === affiliates.transavia &&
      isResultsPage &&
      sortedOptionList?.length > 0
    ) {
      setDepartureOptions(sortedOptionList);
    }
  }, [sortedOptionList]);

  return (
    <>
      <div
        className="departure-input"
        onClick={handleOpenDropdown}
        ref={containerRef}
      >
        <div className="input-placeholder">
          {selectedDeparture?.departureLocationName}
        </div>
        <div className={`dropdown-caret ${isOpen && "rotate"}`}></div>
      </div>
      {isOpen && sortedOptionList && (
        <div className="departure-dropdown-component">
          <div className="departure-dropdown-container">
            <div className="departure-options-container">
              {sortedOptionList.map(
                (option) =>
                  option && (
                    <div
                      className={`departure-row-content ${
                        handleIsMobileSelection(option)
                          ? "selected-in-dropdown-row"
                          : ""
                      }`}
                      key={option.departureLocationCode}
                      onClick={() => selectDepartureLocation(option)}
                    >
                      {handleIsMobileSelection(option) && (
                        <div className="departure-selection-highlight-bar"></div>
                      )}
                      <div className="selected-row">
                        {option.departureLocationName}
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
