import "./accommodation-options.component.scss";
import { HotelImageFlipper } from "../hotel/hotel-image-flipper/hotel-image-flipper.component";
import { LocalStorageService } from "../../services/local-storage.service";
import { culturesCodeEnum } from "../../common/enums/cultureCodesEnum";
import { ReactComponent as IconRoomTypeKLM } from "../../assets/icons/klm/hotel-S.svg";
import { ReactComponent as IconRoomTypeTransavia } from "../../assets/icons/transavia/Hotel.svg";
import { ReactComponent as IconMealKLM } from "../../assets/icons/klm/meals-S.svg";
import { ReactComponent as IconMealTransavia } from "../../assets/icons/transavia/meals-S.svg";
import { ReactComponent as SelectedIconKLM } from "../../assets/icons/check-round-S.klm.svg";
import { GetAffiliate } from "../../services/general.service";
import { affiliates } from "../../common/enums/affiliates";
import { GetMealType } from "../../common/enums/meal-types";
import { GenericButton } from "../generic-button/generic-button";
import { accommodationOptionsEnum } from "../../common/enums/accommodationOptions";
import { CircularProgress } from "@mui/material";
import {
  primaryCategories,
  accomodationCategories,
  accomodationCategoriesNL,
  klmAccomodationIcons,
  transaviaAccomodationIcons,
  klmAccomodationIconsNL,
  transaviaAccomodationIconsNL,
} from "../../common/constants/accomodationCategories";
import { ReactComponent as IconPraticalKLM } from "../../assets/icons/klm/pratical-icon-klm.svg";
import { ReactComponent as IconPraticalTransavia } from "../../assets/icons/transavia/practical-icon-transavia.svg";
import { SessionTypes } from "../../common/constants/sessionTypes";
import { useEffect, useState, Fragment } from "react";
import { AccommodationOptionDetailsModal } from "../accommodation-option-details-modal/accommodation-option-details-modal";

export const AccommodationOptionsComponent = ({
  accommodationOptions,
  contentfulButtons,
  contentfulAccommodationSection,
  contentfulAccommodationShowDetails,
  authInfo,
  performRoomOptionSelection,
}) => {
  const culture = LocalStorageService.getCulture();
  const [moreOptions, setMoreOptions] = useState(false);
  const [isWaitingForNetwork, setIsWaitingForNetwork] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [isDetailsPopupOpen, setIsDetailsPopupOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [categoriesData, setCategoriesData] = useState([]);
  const [uncategorizedData, setUncategorizedData] = useState([]);
  const oneRoomAccomodation = 1;
  const emptyRoomTypeDetails = 0;

  const accomodationCategoriesLocalized =
    culture === culturesCodeEnum.NETHERLANDS
      ? accomodationCategoriesNL
      : accomodationCategories;

  const getAffiliateIcons = () => {
    const isKLM = GetAffiliate() === affiliates.klm;
    const isNL =
      LocalStorageService.getCulture() === culturesCodeEnum.NETHERLANDS;

    if (isKLM) {
      return isNL ? klmAccomodationIconsNL : klmAccomodationIcons;
    } else {
      return isNL ? transaviaAccomodationIconsNL : transaviaAccomodationIcons;
    }
  };
  

  const renderKeyRoomDetails = (uncategorized, limit = 3) => {
    const affiliateIcons = getAffiliateIcons();
    const isKLM = GetAffiliate() === affiliates.klm;
  
    const prioritizedItems = uncategorized.filter((item) =>
      primaryCategories.some((category) =>
        item
          .toLowerCase()
          .includes(accomodationCategories[category]?.toLowerCase())
      )
    );
  
    const remainingItems = uncategorized.filter(
      (item) => !prioritizedItems.includes(item)
    );
  
    const finalItems = limit
      ? [...prioritizedItems, ...remainingItems].slice(0, limit)
      : [...prioritizedItems, ...remainingItems];
  
    return finalItems.map((item, index) => {
      const matchedCategory = Object.entries(
        accomodationCategoriesLocalized
      ).find(([_, keyword]) =>
        item.toLowerCase().includes(keyword.toLowerCase())
      );
  
      const formatTextWithBoldNumbers = (text) => {
        return text.split(/(\d+)/).map((part, i) =>
          /\d+/.test(part) ? (
            <span key={i} className="bold-number">
              {part}
            </span>
          ) : (
            part
          )
        );
      };
  
      let icon;
  
      if (matchedCategory) {
        const [category] = matchedCategory;
        const iconKey = accomodationCategoriesLocalized[category];
        icon = affiliateIcons[iconKey];
      } else {
        icon = isKLM ? <IconPraticalKLM /> : <IconPraticalTransavia />;
      }
  
      return (
        <div key={index} className="amenities-category-option">
          {icon}
          <div>{formatTextWithBoldNumbers(item)}</div>
        </div>
      );
    });
  };  

  const handleOnExpandButtonClick = () => {
    setMoreOptions(!moreOptions);
  };

  const handleOnSelected = async (roomOptionKey) => {
    if (!isWaitingForNetwork) {
      setSelectedRoom(roomOptionKey);
      setIsWaitingForNetwork(true);
      await performRoomOptionSelection(roomOptionKey);
      setMoreOptions(false);
      setIsWaitingForNetwork(false);
    }
  };

  const numberOfOptionsToShow = () => {
    if (moreOptions) {
      return accommodationOptions?.length;
    }
    return accommodationOptionsEnum.defaultNumberOfOptions;
  };

  const handleRoomDetailsClick = (room) => {
    setModalData(room);
    setCategoriesData(room.hotelRoomTypeDetails.categories);
    setUncategorizedData(room.hotelRoomTypeDetails.uncategorized);
    setIsDetailsPopupOpen(true);
  };

  return (
    <div className="accommodation-options-component">
      {accommodationOptions && (
        <>
          <div className="accommodation-options-title">
            <div className="header">
              {contentfulAccommodationSection &&
                contentfulAccommodationSection.accommodation}
            </div>
            <div className="sub-header">
              {contentfulAccommodationSection &&
                contentfulAccommodationSection.roomType}
            </div>
          </div>
          <div className="accommodation-options-wrapper">
            {accommodationOptions?.map(
              (room, index) =>
                numberOfOptionsToShow() > index && (
                  <Fragment>
                    <div
                      key={index}
                      className={`accommodation-options-container ${
                        isWaitingForNetwork &&
                        selectedRoom !== room.roomOptionKey
                          ? "opacity-50"
                          : ""
                      } ${
                        room.selected ? "accommodation-options-selected" : ""
                      }`}
                    >
                      <div className="hotel-image">
                        <HotelImageFlipper hotelImages={room.images} />
                      </div>
                      <div className="hotel-description">
                        <div className="accommodation-info">
                          <div className="accommodation-description">
                            {contentfulAccommodationSection &&
                              `
                              ${room?.numberOfRooms} 
                              ${
                                room?.numberOfRooms === oneRoomAccomodation
                                  ? contentfulAccommodationSection.room
                                  : contentfulAccommodationSection.rooms
                              },
                              ${room?.numberOfPassengers} ${
                                contentfulAccommodationSection.travellers
                              }`}
                          </div>
                          <div className="amenities-options">
                            <div className="categories-container">
                              <div
                                className={`amenities-item ${
                                  room?.roomDescriptions?.length <=
                                  oneRoomAccomodation
                                    ? "amenities-item-one"
                                    : "amenities-item-group"
                                }`}
                              >
                                <div className="amenities-item-meal">
                                  {GetAffiliate() === affiliates.klm ? (
                                    <IconMealKLM
                                      className={`${
                                        room?.mealIncluded
                                          ? "amenities-icon"
                                          : "amenities-icon-not-selected"
                                      }`}
                                    />
                                  ) : (
                                    <IconMealTransavia
                                      className={`${
                                        room?.mealIncluded
                                          ? "amenities-icon-selected"
                                          : "amenities-icon-not-selected"
                                      }`}
                                    />
                                  )}
                                  {GetMealType(room.mealTypeCode)}
                                </div>
                                <div
                                  className={`amenities-rooms rooms-desktop ${
                                    room?.roomDescriptions?.length <=
                                    oneRoomAccomodation
                                      ? "rooms-desktop-mr-added"
                                      : "rooms-desktop-mr-none"
                                  } `}
                                >
                                  {GetAffiliate() === affiliates.klm ? (
                                    <IconRoomTypeKLM className="amenities-icon" />
                                  ) : (
                                    <IconRoomTypeTransavia className="amenities-icon" />
                                  )}
                                  {room.roomDescriptions.map(
                                    (roomDescription) => (
                                      <div key={roomDescription}>
                                        {roomDescription}
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="amenities-item">
                                <div className="amenities-item-category">
                                  <div className="amenities-items-container">
                                    <div
                                      className={`amenities-rooms rooms-mobile ${
                                        room?.roomDescriptions?.length <=
                                        oneRoomAccomodation
                                          ? "rooms-mobile-mr-added"
                                          : "rooms-mobile-mr-none"
                                      }`}
                                    >
                                      {GetAffiliate() === affiliates.klm ? (
                                        <IconRoomTypeKLM className="amenities-icon" />
                                      ) : (
                                        <IconRoomTypeTransavia className="amenities-icon" />
                                      )}
                                      {room.roomDescriptions.map(
                                        (roomDescription) => (
                                          <div key={roomDescription}>
                                            {roomDescription}
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <div
                                      className={`accomodation-categories-options ${
                                        room?.roomDescriptions?.length <=
                                        oneRoomAccomodation
                                          ? "categories-option-visible"
                                          : "categories-option-hidden"
                                      }`}
                                    >
                                        {renderKeyRoomDetails(room?.hotelRoomTypeDetails?.uncategorized || [], 3)}
                                    </div>
                                    {authInfo === SessionTypes.defaultType && (
                                      <div
                                        className="show-more-accomodation-btn-mobile"
                                        onClick={() =>
                                          handleRoomDetailsClick(room)
                                        }
                                      >
                                        {(room?.hotelRoomTypeDetails?.categories
                                          ?.length > emptyRoomTypeDetails ||
                                          room?.hotelRoomTypeDetails
                                            ?.uncategorized?.length > 2) &&
                                          room.roomDescriptions?.length <=
                                            oneRoomAccomodation &&
                                          contentfulAccommodationShowDetails &&
                                          contentfulAccommodationShowDetails}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {authInfo === SessionTypes.defaultType && (
                                <div
                                  className="show-more-accomodation-btn-desktop"
                                  onClick={() => handleRoomDetailsClick(room)}
                                >
                                  {(room?.hotelRoomTypeDetails?.categories
                                    ?.length > emptyRoomTypeDetails ||
                                    room?.hotelRoomTypeDetails?.uncategorized
                                      ?.length > 2) &&
                                    room.roomDescriptions?.length <=
                                      oneRoomAccomodation &&
                                    contentfulAccommodationShowDetails &&
                                    contentfulAccommodationShowDetails}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="price-selection-control">
                          <div
                            className={
                              room.selected ? "inclusive" : "additional-cost"
                            }
                          >
                            {room.price !== "" && Number(room.price) > 0 && (
                              <span className="price-sign">+</span>
                            )}
                            {Number(room.price) < 0 && (
                              <span className="price-sign">-</span>
                            )}
                            {room.price === "" && contentfulAccommodationSection
                              ? contentfulAccommodationSection.inclusive
                              : ` € 
                                            ${
                                              Number(room.price) > 0
                                                ? room.price
                                                : room.price.replace("-", "")
                                            }`}
                            {room.price !== "" && (
                              <span>
                                {" "}
                                {contentfulAccommodationSection &&
                                  contentfulAccommodationSection.pricePerPerson}
                              </span>
                            )}
                          </div>
                          <div className="select-accommodation-button">
                            {!room.selected ? (
                              <GenericButton
                                className="button-selection"
                                disabledState={
                                  isWaitingForNetwork &&
                                  selectedRoom === room.roomOptionKey
                                }
                                onClick={() =>
                                  handleOnSelected(room.roomOptionKey)
                                }
                              >
                                {isWaitingForNetwork &&
                                selectedRoom === room.roomOptionKey ? (
                                  <>
                                    <div className="spinner-button-wrapper">
                                      <div className="spinner-button">
                                        <CircularProgress
                                          className="button-loading-spinner"
                                          size={6}
                                        />
                                        <div className="loading-text">
                                          {contentfulButtons &&
                                            contentfulButtons[0]?.fields
                                              ?.loadingState}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  contentfulButtons &&
                                  contentfulButtons[0]?.fields?.select
                                )}
                              </GenericButton>
                            ) : (
                              <div className="selected-accommodation">
                                <SelectedIconKLM className="selected-accommodation-icon" />
                                {contentfulButtons &&
                                  contentfulButtons[0]?.fields?.selected}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {modalData && (
                      <AccommodationOptionDetailsModal
                        isOpen={isDetailsPopupOpen}
                        handleClose={() => setIsDetailsPopupOpen(false)}
                        roomDetails={modalData}
                        getAffiliateIcons={getAffiliateIcons}
                        categoriesData={categoriesData}
                        uncategorizedData={uncategorizedData}
                        renderKeyRoomDetails={renderKeyRoomDetails}
                        contentfulData={contentfulAccommodationSection}
                      />
                    )}
                  </Fragment>
                )
            )}
          </div>
          {accommodationOptions.length > 3 ? (
            <GenericButton className="more-accommodation-button">
              {moreOptions && (
                <div
                  className="button-container"
                  onClick={handleOnExpandButtonClick}
                >
                  <div>
                    {contentfulButtons &&
                      contentfulButtons[0]?.fields?.showLessRooms}
                  </div>
                  <div className="dropdown-caret rotate"></div>
                </div>
              )}
              {!moreOptions && (
                <div
                  className="button-container"
                  onClick={handleOnExpandButtonClick}
                >
                  {contentfulButtons &&
                    contentfulButtons[0]?.fields?.showAllRooms}
                  <div className="dropdown-caret"></div>
                </div>
              )}
            </GenericButton>
          ) : (
            <div className="spacer-sm"></div>
          )}
        </>
      )}
    </div>
  );
};
