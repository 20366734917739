import { useRef, useEffect } from "react";

export const GetDateOnYearFromNow = () => {
  const current = new Date();
  const nextYear = new Date();
  nextYear.setFullYear(current.getFullYear() + 1);

  return nextYear;
};

export const GetAffiliate = () => {
  return process.env?.REACT_APP_AFFILIATE ?? "KLM";
};

export const GetApiUrl = () => {
  return process.env?.REACT_APP_API_URL;
};

export const GetLogoPath = (airlineCode) =>
  process.env?.REACT_APP_AIRHEX_BASE_URL + `/AL_${airlineCode}_sq.png`;

//String manipulation for mobile view to hide the year of date
export const removeYearFromDate = (responseDate) => {
  let departureDate = responseDate?.split(" ");
  departureDate?.pop();
  let modifiedDepartureDate = departureDate?.join(" ");

  return modifiedDepartureDate;
};

export const formatDateToYMD = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const compareStates = (previous, current) => {
  let changes = [];
  const allKeys = new Set([...Object.keys(previous), ...Object.keys(current)]);
  for (let key of allKeys) {
    if (previous[key] !== current[key]) {
      changes = [...changes, key];
    }
  }

  return changes;
};
