import React, { useState, useRef } from "react";
import "./dropdown.scss";

export const DropdownComponent = (
  { children, hasOwnInput, defaultPlaceholder, useFormStlying },
  props
) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleBlur = (event) => {
    if (!containerRef.current.contains(event.relatedTarget)) {
      setIsOpen(false);
    }
  };

  return (
    <div className="dropdown-component">
      <div
        ref={containerRef}
        className={`dropdown-container ${isOpen ? "dropdown-active" : ""}`}
        tabIndex={-1}
        onBlur={handleBlur}
        onFocus={toggleDropdown}
      >
        <span
          className={`dropdown-text ${
            useFormStlying ? " dropdown-text-form-styling" : ""
          }`}
        >
          {hasOwnInput ? (
            children
          ) : (
            <span className={isOpen ? "placeholder-bold" : ""}>
              {defaultPlaceholder}
            </span>
          )}
        </span>

        {!hasOwnInput &&
          ((isOpen && <div className="dropdown-caret rotate"></div>) ||
            (!isOpen && <div className="dropdown-caret"></div>))}

        {isOpen && !hasOwnInput && (
          <>
            <div className="dropdown-options">{children}</div>
            <div className="arrow-indicator" />
          </>
        )}
      </div>
    </div>
  );
};
