import "./App.scss";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/rootReducer";
import { HeaderComponent } from "./components/header/header.component";
import { ResultsPageComponent } from "./pages/results/results-page.component";
import { ExtrasPageComponent } from "./pages/extras/extras-page.component";
import { ConfirmationPageComponent } from "./pages/confirmation/confirmation-page.component";
import { PassengerDetailsPageComponent } from "./pages/passenger-details/passenger-details.component";
import { MAIN_ROUTE, PagesConstant } from "./common/constants/pages";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { removeModal } from "./store/modal/action";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { ModalComponent } from "./components/modal/modal.component";
import ThemeSwitcher from "./components/theme-switcher/theme-switcher";
import {
  getContentfulAllContextualFields,
  getContentfulEntriesLocale,
  getStoredLocale,
} from "./services/contentful.service";
import ContentfulContext from "./store/contentful/contentful-context";
import { SearchPageComponent } from "./pages/search/search";
import { LocalStorageService } from "./services/local-storage.service";
import { affiliateDetails } from "./environments/affiliates";
import config from "./environments/config.json";
import { languageDictionary } from "./common/constants/language";
import { HolidayPageComponent } from "./pages/holiday/holiday.component";
import { CustomModal } from "./components/custom-modal/custom-modal";
import { WaitingModalComponent } from "./components/waiting-modal/waiting-modal.component";
import { PaymentPageComponent } from "./pages/payment/payment-page.component";
import { affiliates } from "./common/enums/affiliates";
import { FooterComponent } from "./components/footer/footer-component";
import { GetWaitingModalMessage } from "./components/waiting-modal/waiting-modal-message-service";
import { GetAffiliate } from "./services/general.service";
import { pages } from "./common/enums/pages";
import { HotJarService } from "./services/hot-jar/hot-jar.service";
import { metaTag } from "./common/constants/meta";
import { environments } from "./common/enums/environments";
import { searchParamsEnum } from "./common/enums/searchParamsEnum";
import { setContentfulEntriesReducer } from "./store/contentful/slice";
import { CookiesProvider } from "react-cookie";
import { AnalyticsService } from "./services/analytics/analytics.service";

const App = () => {
  const dispatch = useDispatch();
  const [contentfulEntries, setContentfulEntries] = useState();
  const [culture, setCulture] = useState();
  const [previousCulture, setPreviousCulture] = useState();
  const [language, setLanguage] = useState("");
  const [isWaitingForNetwork, setIsWaitingForNetwork] = useState(false);
  const [waitingScreenMessage, setWaitingScreenMessage] = useState("");
  const [deepLinkParams, setDeepLinkParams] = useState(null);
  const [queryParameters, setQueryParameters] = useState();
  const [isMobile, setIsMobile] = useState(false);
  const [checkOutData, setCheckOutData] = useState({});
  const [shouldShowModal, setIsCustomMobileShown] = useState(false);
  const [metaDescription, setMetaDescription] = useState("");
  const [isHolidayPage, setIsHolidayPage] = useState(false);
  const [modalData, setModalData] = useState({
    children: "",
    showCancelButton: false,
    showConfirmButton: false,
    confirmButtonText: "OK",
  });
  const storedContentfulEntries = useSelector(
    (state) => state.contentful.contentfulEntries
  );

  useEffect(() => {
    setIsHolidayPage(window.location.pathname.includes(PagesConstant.HOLIDAY));
  }, [window.location.pathname]);
  const searchParameters = new URLSearchParams(window.location.search);

  useEffect(() => {
    const affiliate = GetAffiliate();
    const culture = LocalStorageService.getCulture();

    if (affiliate === affiliates.transavia) {
      setMetaDescription(metaTag.transavia[culture]);
    } else {
      setMetaDescription(metaTag.klm[culture]);
    }
  }, [culture]);

  useEffect(() => {
    dispatch(removeModal());
  }, [dispatch]);

  useEffect(() => {
    setFaviconAndTitle();
    AnalyticsService.init();
    if (
      window.location.hostname !== environments.localhost &&
      process.env.REACT_APP_HOTJAR_ID
    ) {
      HotJarService.initHotJar();
    }
    retrieveContentfulEntries();
  }, []);

  useEffect(() => {
    const parsedParameters = parseQueryParams();
    setQueryParameters(parsedParameters);

    const urlCulture = parsedParameters.culture;
    const localStorageCulture = LocalStorageService.getCulture();

    const isValidCulture = config.AVAILABLE_LANGUAGES.includes(urlCulture);
    let newCulture = config.DEFAULT_CULTURE;

    if (isValidCulture && urlCulture !== localStorageCulture) {
      newCulture = urlCulture;
      LocalStorageService.setCulture(urlCulture);
    } else if (
      localStorageCulture &&
      config.AVAILABLE_LANGUAGES.includes(localStorageCulture)
    ) {
      newCulture = localStorageCulture;
    }

    setCulture(newCulture);
    setLanguage(newCulture.substring(0, 2).toLowerCase());
  }, []);

  const showWaitingScreen = (messageType) => {
    setWaitingScreenMessage(messageType);
  };

  const hideWaitingScreen = () => {
    setWaitingScreenMessage(null);
  };

  const assignCheckOutData = (newDataObject) => {
    setCheckOutData(newDataObject);
  };

  const showCustomModal = (
    headingText,
    children,
    showCancelButton,
    showConfirmButton,
    cancelButtonText,
    confirmButtonText,
    confirmButtonAction = () => {}
  ) => {
    setModalData({
      headingText: headingText,
      children: children,
      showCancelButton: showCancelButton,
      showConfirmButton: showConfirmButton,
      cancelButtonText: cancelButtonText,
      confirmButtonText: confirmButtonText,
      confirmButtonAction: confirmButtonAction,
    });
    setIsCustomMobileShown(true);
  };

  const hideCustomMobile = () => {
    setIsCustomMobileShown(false);
  };

  const handleCultureChange = (selectedCulture) => {
    const localStorageCulture = LocalStorageService.getCulture();

    if (!culture && localStorageCulture) {
      setPreviousCulture(localStorageCulture);
      setCulture(localStorageCulture);
      setContentfulEntries(storedContentfulEntries);
    } else {
      setPreviousCulture(culture);
      setCulture(selectedCulture);
      LocalStorageService.setCulture(selectedCulture);
      retrieveContentfulEntries();
    }
  };

  const parseQueryParams = () => {
    let queryParametersObject = {};
    for (const [key, value] of searchParameters) {
      if (
        pages.results.some((route) =>
          window.location.pathname.includes(route)
        ) ||
        pages.holiday.some((route) => window.location.pathname.includes(route))
      ) {
        queryParametersObject[key.toLocaleLowerCase()] = value;
      } else {
        queryParametersObject[key] = value;
      }
    }
    return queryParametersObject;
  };

  const setFaviconAndTitle = () => {
    const currentAffiliateDetails = affiliateDetails.find(
      (x) => x.name.toUpperCase() == process.env?.REACT_APP_AFFILIATE
    );
    if (currentAffiliateDetails) {
      document.title = currentAffiliateDetails.tabTitleEnglish;
      const favicon = document.getElementById("favicon");
      favicon.href = `/app/affiliates/${currentAffiliateDetails.faviconPath}/favicon.png`;
      const appleTouchIcon = document.getElementById("apple-touch-icon");
      appleTouchIcon.href = `/app/affiliates/${currentAffiliateDetails.faviconPath}/apple-touch-icon.png`;
    }
  };

  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <Navigate to={`/${PagesConstant.RESULTS}`} replace={true} />,
      },
      {
        path: "app/",
        element: <Navigate to={`/${PagesConstant.RESULTS}`} replace={true} />,
      },
      {
        path: MAIN_ROUTE,
        children: [
          {
            path: MAIN_ROUTE,
            element: <Navigate to={PagesConstant.RESULTS} replace={true} />,
          },

          { path: PagesConstant.SEARCH, element: <SearchPageComponent /> },
          {
            path: PagesConstant.RESULTS,
            element: (
              <ResultsPageComponent
                isMobile={isMobile}
                queryParameters={queryParameters}
                culture={culture}
                isWaitingForNetwork={isWaitingForNetwork}
                setIsWaitingForNetwork={setIsWaitingForNetwork}
                showCustomModal={showCustomModal}
                hideCustomMobile={hideCustomMobile}
                showWaitingScreen={showWaitingScreen}
                hideWaitingScreen={hideWaitingScreen}
              />
            ),
          },
          {
            path: PagesConstant.HOLIDAY,
            element: (
              <HolidayPageComponent
                queryParameters={queryParameters}
                culture={culture}
                isWaitingForNetwork={isWaitingForNetwork}
                setIsWaitingForNetwork={setIsWaitingForNetwork}
                deepLinkParams={deepLinkParams}
                showCustomModal={showCustomModal}
                hideCustomMobile={hideCustomMobile}
                showWaitingScreen={showWaitingScreen}
                hideWaitingScreen={hideWaitingScreen}
                setDeepLinkParams={setDeepLinkParams}
              />
            ),
          },
          {
            path: PagesConstant.EXTRAS,
            element: (
              <ExtrasPageComponent
                checkoutData={checkOutData}
                isWaitingForNetwork={isWaitingForNetwork}
                setDeepLinkParams={setDeepLinkParams}
                assignCheckOutData={assignCheckOutData}
                setIsWaitingForNetwork={setIsWaitingForNetwork}
                showCustomModal={showCustomModal}
              />
            ),
          },
          {
            path: PagesConstant.CHECKOUT,
            element: (
              <PassengerDetailsPageComponent
                showCustomModal={showCustomModal}
                hideCustomMobile={hideCustomMobile}
                showWaitingScreen={showWaitingScreen}
                hideWaitingScreen={hideWaitingScreen}
                assignCheckOutData={assignCheckOutData}
                setDeepLinkParams={setDeepLinkParams}
              />
            ),
          },
          {
            path: PagesConstant.PAYMENT,
            element: (
              <PaymentPageComponent
                showCustomModal={showCustomModal}
                hideCustomMobile={hideCustomMobile}
                showWaitingScreen={showWaitingScreen}
                hideWaitingScreen={hideWaitingScreen}
                assignCheckOutData={assignCheckOutData}
                setDeepLinkParams={setDeepLinkParams}
                checkOutData={checkOutData}
                queryParameters={queryParameters}
              />
            ),
          },
          {
            path: PagesConstant.CONFIRMATION,
            element: (
              <ConfirmationPageComponent
                showCustomModal={showCustomModal}
                hideCustomMobile={hideCustomMobile}
                showWaitingScreen={showWaitingScreen}
                hideWaitingScreen={hideWaitingScreen}
                assignCheckOutData={assignCheckOutData}
                checkOutData={checkOutData}
                queryParameters={queryParameters}
              />
            ),
          },
        ],
      },
    ],
    { basename: "/app" }
  );

  const parseModalChildren = (childValues) => {
    return <div>{childValues}</div>;
  };

  const retrieveContentfulEntries = () => {
    getContentfulAllContextualFields(LocalStorageService.getCulture()).then(
      (content) => {
        setContentfulEntries(content);
        dispatch(setContentfulEntriesReducer(content));
      }
    );
  };

  const newLocaleSelected = () => {
    const contentfulEntriesLocale = getContentfulEntriesLocale(
      storedContentfulEntries
    );
    const storedLocale = getStoredLocale();
    return contentfulEntriesLocale !== storedLocale;
  };

  return (
    <Provider store={store}>
      <CookiesProvider>
        <ContentfulContext.Provider
          value={{ contentfulData: contentfulEntries }}
        >
          <PersistGate loading={null} persistor={persistor}>
            <Helmet>
              <meta name="description" content={metaDescription} />
            </Helmet>
            <ThemeSwitcher />
            <div className={`App ${isHolidayPage ? "holiday-overflow" : ""}`}>
              <HeaderComponent
                isMobile={isMobile}
                setIsMobile={setIsMobile}
                queryParameters={queryParameters}
                culture={culture}
                language={language}
                handleLanguageChange={handleCultureChange}
                previousCulture={previousCulture}
                deepLinkParams={deepLinkParams}
                showWaitingScreen={showWaitingScreen}
                hideWaitingScreen={hideWaitingScreen}
                checkOutData={checkOutData}
                isHolidayPage={isHolidayPage}
              />
              <WaitingModalComponent
                isMobile={isMobile}
                waitingScreenMessage={waitingScreenMessage}
              />
              <CustomModal
                isMobile={isMobile}
                shouldShow={shouldShowModal}
                headingText={modalData.headingText}
                showCancelButton={modalData.showCancelButton}
                showConfirmButton={modalData.showConfirmButton}
                cancelButtonText={modalData.cancelButtonText}
                confirmButtonText={modalData.confirmButtonText}
                confirmButtonAction={modalData.confirmButtonAction}
                handleClose={hideCustomMobile}
              >
                {parseModalChildren(modalData.children)}
              </CustomModal>
              <RouterProvider router={router} />
              <ModalComponent />
              <FooterComponent culture={culture} />
            </div>
          </PersistGate>
        </ContentfulContext.Provider>
      </CookiesProvider>
    </Provider>
  );
};

export default App;
